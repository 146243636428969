





























import { Component, Provide, Vue } from "vue-property-decorator";
import SidebarItem from "@/components/Sidebar/SidebarItem.vue";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import moment from "moment";
import { getBuildInfo } from "vue-cli-plugin-build-info/plugin";
import ActionButton from "@/components/Button/ActionButton.vue";
import SidebarItemNarrow from "@/components/Sidebar/SidebarItemNarrow.vue";
import {
  MODULE_ACCOUNTING,
  MODULE_AUTOMATIC_PAYMENTS,
  MODULE_COMMUNICATION,
  MODULE_COMPANY,
  MODULE_COST_BEARERS,
  MODULE_CUSTOMERS,
  MODULE_EXAM,
  MODULE_REPORTS,
  MODULE_TASK,
  MODULE_THEORY,
  MODULE_TIME_PLANNER,
} from "@/constants/Permissions";
import { namespace } from "vuex-class";

const DocumentModule = namespace("document");

@Component({
  components: { SidebarItem, ActionButton, SidebarItemNarrow },
})
export default class Sidebar extends Vue {
  public name = "Sidebar";

  @DocumentModule.Action("sendRouteInfo")
  public sendRouteInfo: any;

  public hideNames = true;
  public sidebarItemsStyle = "sidebar-items";
  public sidebarArrowIcon = ["fat", "angle-double-left"];
  public sidebarArrowTooltip = "Seitenleiste schließen";

  public items = [
    {
      title: this.$t("sidebar.dashboard").toString().toUpperCase(),
      path: "/",
      icon: "house",
    },
    {
      title: this.$t("sidebar.communication"),
      path: "/communication",
      icon: "envelope-dot",
      meta: { title: "" },
      can: MODULE_COMMUNICATION,
      badge: "CommunicationBadge",
      children: [
        {
          title: this.$t("sidebar.onboarding"),
          path: "/registration",
          badge: "RegistrationBadge",
        },
        {
          title: this.$t("auth.email"),
          path: "/email",
          badge: "EmailBadge",
        },
        {
          title: this.$t("sidebar.broadcast"),
          path: "/broadcast",
        },
        {
          title: this.$t("sidebar.messaging"),
          path: "/messaging",
        },
      ],
    },
    {
      title: this.$t("sidebar.todo"),
      path: "/todo",
      icon: "list-check",
      can: MODULE_TASK,
    },
    {
      title: this.$t("sidebar.students"),
      path: "/students",
      icon: "address-card",
      can: MODULE_CUSTOMERS,
    },
    {
      title: this.$t("students.payers"),
      path: "/payers",
      icon: "building",
      can: MODULE_COST_BEARERS,
    },
    {
      title: this.$t("sidebar.scheduler"),
      path: "/scheduler",
      icon: "calendar-days",
      can: MODULE_TIME_PLANNER,
    },
    {
      title: this.$t("sidebar.theory_lessons"),
      path: "/theory",
      icon: "screen-users",
      can: MODULE_THEORY,
    },
    {
      title: this.$t("students.exams"),
      path: "/exams",
      icon: "graduation-cap",
      can: MODULE_EXAM,
      children: [
        {
          title: this.$t("sidebar.theory_exams"),
          path: "/theory-exam",
        },
        {
          title: this.$t("sidebar.practical_exams"),
          path: "/practical-exams",
        },
      ],
    },
    {
      title: this.$t("sidebar.reports"),
      path: "/reports",
      icon: "chart-pie",
      can: MODULE_REPORTS,
      children: [
        {
          title: this.$t("sidebar.finances"),
          path: "/finances",
        },
        {
          title: this.$t("sidebar.training"),
          path: "/training",
        },
        {
          title: this.$t("sidebar.planning"),
          path: "/planning",
        },
        {
          title: this.$t("sidebar.learn"),
          path: "/learn",
        },
        {
          title: this.$t("sidebar.export"),
          path: "/export",
        },
        {
          title: this.$t("general.cost_invoice"),
          path: "/invoice",
        },
      ],
    },
    {
      title: this.$t("sidebar.automatic_payments"),
      path: "/automatic-payments",
      icon: "wallet",
      can: MODULE_AUTOMATIC_PAYMENTS,
      children: [
        {
          title: this.$t("sidebar.automatic_payments_overview"),
          path: "/automatic-payments-overview",
        },
        {
          title: this.$t("sidebar.automatic_payments_classic_pay"),
          path: "/automatic-payments-classic-pay",
        },
        {
          title: this.$t("sidebar.automatic_payments_live_pay"),
          path: "/automatic-payments-live-pay",
        },
        {
          title: this.$t("sidebar.automatic_payments_flash_pay"),
          path: "/automatic-payments-flash-pay",
        },
        {
          title: this.$t("sidebar.automatic_payments_payment_documents"),
          path: "/automatic-payments-payment-documents",
        },
      ],
    },
    {
      title: this.$t("sidebar.accounting"),
      path: "/accounting",
      icon: "calculator-simple",
      can: MODULE_ACCOUNTING,
      children: [
        {
          title: this.$t("sidebar.fibufix"),
          path: "/fibu-fix",
          external: true,
        },
        {
          title: this.$t("sidebar.persofix"),
          path: "/perso-fix",
        },
      ],
    },
    {
      title: this.$t("sidebar.company"),
      path: "/company",
      icon: "car-building",
      can: MODULE_COMPANY,
      children: [
        {
          title: this.$t("general.base_data"),
          path: "/master-data",
        },
        {
          title: this.$t("sidebar.configurations"),
          path: "/configurations",
        },
        {
          title: this.$t("sidebar.accounting"),
          path: "/accounting",
        },
        {
          title: this.$t("sidebar.instructors"),
          path: "/instructors",
          alias: ["/instructors/create", "/instructors/edit/:id"],
        },
        {
          title: this.$t("sidebar.vehicles"),
          path: "/vehicles",
        },
        {
          title: this.$tc("general.product", 2),
          path: "/products",
        },
        {
          title: this.$t("general.price_list"),
          path: "/price-lists",
        },
        {
          title: this.$tc("general.branch", 2),
          path: "/branches",
        },
      ],
    },
    {
      title: this.$t("sidebar.help"),
      path: "https://support.fahrschulcockpit.de/portal/de/home",
      icon: "user-nurse",
      external: true,
    },
  ];

  protected backendVersionResponse: { version: string; time: string } | null = null;

  public activeItem?: any = {};

  @Provide("activeMenuHandler")
  public activeMenuHandler(item: any): void {
    this.activeItem = item;
  }

  public mounted(): void {
    this.getBackendVersion();
  }

  public async getBackendVersion(): Promise<void> {
    if (process.env.VUE_APP_ENV === "development") return;
    const url = process.env.VUE_APP_BACKEND_BASE_URL + "/actuator/info";

    return await axios
      .get(url)
      .then((response: AxiosResponse) => {
        this.backendVersionResponse = response.data.build;
      })
      .catch((error: AxiosError) => {
        console.error(error, "error");
      });
  }

  public formatDateTime(date: string): string {
    return moment(date).format("HH:mm DD.MM.YYYY");
  }

  public get frontendVersion(): string {
    const { VERSION } = getBuildInfo();
    return `frontend ${VERSION}`;
  }

  public get frontendTime(): string {
    const { TIMESTAMP } = getBuildInfo();
    return `${this.formatDateTime(TIMESTAMP)}`;
  }

  public get backendVersion(): string {
    if (!this.backendVersionResponse) return "";
    return `backend ${this.backendVersionResponse.version}`;
  }

  public get backendTime(): string {
    if (!this.backendVersionResponse) return "";
    return `${this.formatDateTime(this.backendVersionResponse.time)}`;
  }

  public onHideNamesTrigger() {
    this.hideNames = !this.hideNames;
    if (this.hideNames) {
      this.sidebarItemsStyle = "sidebar-items";
      this.sidebarArrowIcon = ["fat", "angle-double-left"];
      this.sidebarArrowTooltip = "Seitenleiste schließen";
    } else {
      this.sidebarItemsStyle = "sidebarnr-narrow";
      this.sidebarArrowIcon = ["fat", "angle-double-right"];
      this.sidebarArrowTooltip = "Seitenleiste öffnen";
    }
    this.$emit("hide-names", this.hideNames);
  }

  public onLogoClick() {
    const screenName = this.$route.name ? this.$route.name : "";
    this.sendRouteInfo(screenName);
  }
}
