










import { Vue, Component } from "vue-property-decorator";

@Component
export default class LocaleSwitcher extends Vue {
  public name = "LocaleSwitcher";
  public chosenLocale = "";

  public mounted() {
    if (sessionStorage.getItem("lang") === null) {
      this.chosenLocale = this.$i18n.availableLocales[0];
      return;
    }
    this.chosenLocale = this.$i18n.locale;
  }

  public onChangeLanguage(): any {
    sessionStorage.setItem("lang", this.chosenLocale);
    this.$router.go(0);
  }
}
