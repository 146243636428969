import { render, staticRenderFns } from "./SidebarItemNarrow.vue?vue&type=template&id=6f5318c8&"
import script from "./SidebarItemNarrow.vue?vue&type=script&lang=ts&"
export * from "./SidebarItemNarrow.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports